img.du {
  height: 411px;
  display: inline-block;
}

p.info {
  display: inline-block;
  font-family: Nevo;
  font-size: 18pt;
}
