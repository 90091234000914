// talents styles

.best-choice{
  height: 277px;
}

.garage {
  align-self: center;
}

.sections-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.talent-font {
  font-family: Nevo, sans-serif;
}
