.mentions{
  padding: 3% 0;
  h3{
    margin-bottom: 3rem;
  }
  .section-mention{
    margin-bottom: 3rem;
    a{
      text-decoration: underline;
    }
  }
  .last-section-mention{
    a{
      text-decoration: underline;
    }
  }
}
