.header {
  .title-bar {
    background-color: black;
    z-index: 100;
    //position: fixed;
    width: 100%;

    ul {
      z-index: 10;
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-align: center;
    }

    li {
      display: inline-block;
      width: 170px;
    }

    li a {
      display: block;
      color: #fefefe;
      background-color: #000000;
      text-align: center;
      padding: 14px 0px;
      text-decoration: none;
      font-family: Nevo;
      font-size: 9pt;
      font-weight: bold;
    }

    li a:hover {
      color: #000000;
      background-color: #fefefe;
    }

    img {
      height: 25px !important;
      width: 25px !important;
      margin: 0 5px;
    }

    i {
      color: white;
      font-size: 26px;
    }
    button,
    button:hover,
    button:active,
    button:focus {
      outline: none;
      display: block;
      margin: 0 auto;
    }

    .drop-down-container {
      position: relative;
      display: inline-block;

      .burger-menu {
        #menuBurgerMobile {
          background-color: #000;
          top: 49px !important;
          position: absolute;
          overflow-y: hidden;
          -webkit-transition:max-height 500ms ease;
          -moz-transition:max-height 500ms ease;
          -o-transition:max-height 500ms ease;
          transition:max-height 500ms ease;
          ul {
            li {
              display: inline;
              a {
                padding: 10px;
              }
            }
          }
        }
      }

      .social-menu {
        width: 40px;

        #menuSocialMobile {
          width: 40px;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 0px;
          position: absolute;
          overflow-y: hidden;
          -webkit-transition:max-height 500ms ease;
          -moz-transition:max-height 500ms ease;
          -o-transition:max-height 500ms ease;
          transition:max-height 500ms ease;
          z-index: 1;
          .social {
            width: 25px;
            margin: 0 auto;

            img {
              margin: 10px 0;
            }

            img:hover {
              background-color: #fefefe;
            }
          }
        }
      }
    }

    a.morphing-logo {
      display: table;
      margin: 0 auto;
    }
    @media all and (min-width: 90em) {
      .hide-for-custom-size {
        display: none !important;
      }
    }

    @media all and (max-width: 89.9375em) {
      .show-for-custom-size {
        display: none !important;
      }
    }
  }
}
