.who-are-we {
  font-family: 'Nevo', sans-serif;
  strong {
    color: #be1e2d;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 200;
  }
  h3 {
    font-size: 1.4rem;
    text-transform: none;
  }

  .acid {
    padding: 2vh 0 5vh;
    margin: 0;
    color: white;
    strong {
      color: #0a0a0a;
    }
    .separation-line {
      color: white;
      background: white;
    }
  }
  .acid:last-child {
    padding-bottom: 6vh;
  }

  .green {
    background-color: #3dbfbf;
  }

  .orange {
    background-color: #f48f29;
  }

  .red {
    background-color: #d25459;
  }
}

#imgDandy{
  text-align: right;
}

img.dandy {
  height: 230px;
}


img.notrobot {
  height: 255px;
  margin-bottom: 20px;
}

.values{
  font-family: 'Nevo', sans-serif;
}

.sections-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media all and (max-width: 40em) {
  #imgDandy, #imgRobot{
    text-align: center;
  }
}

