#title-placeholder {
  background-color: black;
  color: white;
  text-transform: uppercase;
  position: relative;
  .logo {
    z-index: 10;
  }
  h1 {
    font-size: 2rem;
    margin: 10px 0;
  }
  h2 {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  #bgvid {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    background-size: cover;
  }
}

section.introduction {
  img.robot {
    width: 100px;
  }
  span.text {
    h2 {
      font-family: Nevo;
      font-size: 20pt;
      font-weight: bold;
      color: #a6312b;
    }
    h3 {
      font-family: Nevo;
      font-size: 12pt;
    }
  }
}

@media all and (min-width: 40.063em) {
  section.introduction {
    margin-bottom: 135px;
    padding-top: 50px;
    span.text {
      h2 {
        font-family: Nevo;
        font-size: 28pt;
        font-weight: bold;
        color: #a6312b;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }
      h3 {
        font-family: Nevo;
        font-size: 16pt;
      }
    }
  }
}

