.prestations {
  .container-alliteration {
    position: relative;
  }

  .alliteration {
    position: absolute;
    left: 43%;
    top: 30%;
    width: 250px;
    z-index: -1;
    opacity: 0.4;
    @include breakpoint(medium down) {
      width: 190px;
      left: 40%;
      top: 30%;
    }
    @include breakpoint(small only) {
      display: none;
    }

  }

  .big-letter{
    width: 80%;
    margin-top: 56px;
  }

}

.sections-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}
