// Normalize Styles
@import '../../node_modules/normalize.css/normalize';

@import '../../node_modules/foundation-sites/scss/foundation';
@include foundation-everything(true);

@import '_fonts';
@import '_colors';
@import '_content';


// Import Modules
@import '../_modules/link/link';
@import '../_modules/header/header';
@import '../_modules/clients/clients';
@import '../_modules/separator/separator';
@import '../_modules/technologies/technologies';
@import '../_modules/footer/footer';
@import '../_modules/infos/infos';
@import '../_modules/meetus/meetus';
@import '../_modules/testimonials/testimonials';
@import '../_modules/decouvrir/decouvrir';
@import '../_modules/followus/followus';
@import '../_modules/contentheader/contentheader';
@import '../_modules/map/map';
@import '../_modules/talents/talents';
@import '../_modules/history/history';
@import '../_modules/modal56/modal56';
@import '../_modules/prestations/prestations';
@import '../_modules/mentions/mentions';

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

body {
  overflow-x: hidden;
  font-family: 'Nevo', sans-serif;
  width: auto;

  section, div section {
    margin: 6vh 0;

    header {
      margin: 20px 0;

      .over-hidden {
        overflow: hidden;
      }
    }

  }
}

.cont-carousel {
  height: 72vh;
  background-color: $red-dyou;
}

.cont-devis {
  background-color: #E5006F;
  color: white;

  .row {
    height: 40vh;
  }
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  h3 {
    background: url('../images/offres/title.svg') no-repeat;
    background-size: cover;
    text-align: center;
    font-weight: bold;
  }

  .pandl {
    background: url('../images/references/pandlove.jpg') right no-repeat;
    background-size: contain;
    width: 100%;
    height: 40vh;
  }

}
@include orbit-wrapper;

.orbit {
  img {
    height: 400px;
  }

}

.cont-caseStudies {
  padding: 25px;
}


#astep01,#astep02,#astep03, #astep04, #astep05, #astep06, #astep07, #astep08, #astep09, #astep10, #astep11, #astep12 {
  visibility: hidden;
}

#bstep01,#bstep02,#bstep03, #bstep04, #bstep05, #bstep06, #bstep07, #bstep08, #bstep09, #bstep10, #bstep11, #bstep12 {
  visibility: hidden;
}

a:link, a:visited, a:link:active, a:visited:active {
  text-decoration: none;
  color: black;
}
