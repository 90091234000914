// temoignages styles

.temoignages {
  .wow {
    width: 85px!important;
    margin: -5px 0 0 20px;
  }

  .portrait {
    width: 140px;
    height: 140px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 200;
  }

  i {
    margin: 0 5px;
  }

  span {
    margin: 0 5px;
    text-align: center;
  }

  .name {
    text-transform: uppercase;
  }

}

.border {
  border: 4px solid red;
}
