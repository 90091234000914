
.reveal {
  outline-color: transparent;
  h1 {
    font-size: 2vw;
    padding: 10px;
  }
  button:focus {
    outline: none;
  }
  img {
    width: 80px;
    height: 80px;
  }
}

#map {
  margin: 0 0 7vh;
  width: 100%;
  height: 450px;
}

input, textarea {
  box-shadow: none !important;
  border-left: none!important;
  border-right: none!important;
  border-top: none!important;
}

.red-dyou-button {
  background-color: $red-dyou;
}
