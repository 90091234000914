.suivez-nous {

  div.bubble {
    background-color: black;
    border-radius: 10px;
    height: 430px;
    width: 300px;
    margin: 0 auto;
    overflow:hidden;
    border: 1px solid #a3312b;

    .illustration {
      position: relative;
      height: 9.5rem;
      width: 94%;
      margin: 10px;

      /* Actual image size must have some space at the bottom */
      .image {
        height: 100%;
        width: 100%;
        background-size: cover;
        //not delete
        background-repeat: no-repeat;
        background-position: center ;
        overflow: hidden;
      }

      .icon {
        height: 2.5rem;
        width: 2.5rem;
        position: relative;
        top: 0.5rem;
        left: 0.5rem;
      }
      .fa {
        margin: 25px;
      }
    }

    .title {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        display: block;
        font-family: 'Nevo', sans-serif;
        font-size: 0.8rem;
        text-align: center;
        font-weight: bold;
        color: black;
        overflow: auto;
        padding: 0.5rem 0.5rem;
        height: 42px;
      }

    }
  }

  div.triangle {
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 50px solid black;
    margin-bottom: 50px;
  }

  img.bot {
    height: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .no-padd {
    padding: 0;
    margin: 0;
  }
}
