.clients-content {
  .logo {
    margin: 40px 0;
    .reduced {
      height: 30px;
    }
    img {
      height: 50px
    }
  }
}
