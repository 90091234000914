footer::before {
  content: "";
  background-color: #b42028;
  height: 10px;
  display: block;
}

footer {
  background-color: #000;
  width: 100%;

  .container {
    padding-top: 50px;
    .social {
      display: table;
      margin: 20px auto;
      img {
        margin: 0 5px;
        height: 50px !important;
        width: 50px !important;
      }
    }

    .logo {
      display: table;
      margin: 20px auto;
      img {
        height: 80px;
      }
    }

    .infos {
      text-align: center;
      color: #fff;
      font-size: 0.8rem;
      font-family: 'Nevo', sans-serif;
      font-weight: bold;
      padding: 0;
      float: right;
      display: table;
      text-align: right;
      .name {
        font-size: 1rem;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

@media all and (max-width: 40.063em) {
  footer {
    .container {
      .infos {
        text-align: center;
        float: none;
        margin: 0 auto;
      }
    }
  }
}
