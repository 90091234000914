.container-center {
  display: flex;
  justify-content: center;
}

.ideogram {
  height: 75px;
  margin: 0 15px;
}

.title {
  font-family: Nevo;
  font-weight: bold;
  text-transform: uppercase;
  align-self: center;
  margin: 0;
}

h1.title {
  font-size: 40pt;
}

h2.title {
  font-size: 20pt;
}

@media all and (max-width: 380px) {
  h2.title {
    font-size: 12pt;
  }
}

.separation-line {
  align-self: center;
  width: 100%;
  height: 5px;
  background: black;
}

.separation-line-white {
  align-self: center;
  width: 100%;
  height: 5px;
  background: #fff;
}
