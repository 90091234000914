.modal56 {
  .modal56-confirm-title {
    text-align: center;
    margin-bottom: 15px;
  }

  .modal56-confirm-buttons {
    text-align: center;

    button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .modal56-title-success {
    text-align: center;
    color: #3adb76;
    margin-bottom: 8px;
  }

  .modal56-title-error {
    text-align: center;
    color: #881f0e;
    margin-bottom: 15px;
  }

  .title {
    color: #b42028;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .titleModal {
    font-weight: bold;
    padding-top: 10px;
    font-size: 1.4rem;
    li {
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .space>span {
    margin-right: 10px;
  }
  span.tags {
    padding: 3px 10px;
    margin-right: 3px;
    border-radius: 15px;
    border: 2px solid #d2dff8;
    color: #535353;
    font-size: 14px;
    font-weight: 600;
  }

  .logo {
    margin: auto;
    padding: 10px;
    height: 85px;
    width: 85px;
    background: url('../images/logo_bordeau_gray.svg') center no-repeat;
    border-radius: 50%;
    border: 2px solid $red-dyou;
  }
}


