.colored-content {
  strong {
    color: #be1e2d;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 200;
  }
  h3 {
    font-size: 1.4rem;
    text-transform: none;
  }

  .acid{
    padding: 2vh 0 5vh ;
    margin: 0;
    color: white;
    strong {
      color: #0a0a0a;
    }
    .separation-line {
      color: white;
      background: white;
    }
  }
  .acid:last-child {
    padding-bottom: 6vh;
  }

  .green {
    background-color: #3dbfbf;
  }

  .orange {
    background-color: #f48f29;
  }

  .red {
    background-color: #d25459;
  }
}
