p.text {
  font-family: Nevo;
  font-size: 18pt;
}

.logoTech {
  position: relative;
  float: left;
  margin: 20px 0;

  h4 {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transition-duration: 0.5s;
    opacity: 0;
    display: none;
    bottom:30px;
    text-transform: uppercase;
    position: absolute;
    left: 0px;
    width: 100%;
    color: black;
    font-weight: 400;
    font-size: 19px;
    display: block;
  }

  img {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transition-duration: 0.5s;
    opacity: 1;
    position: relative;
    height: 60px;
    &.increase {
      height: 90px;
    }
    &.increasePlus {
      height: 120px;
    }
    &.reducedLittle {
      height: 39px;
    }
  }
}

.logoTech:hover {
  img {
    opacity: 0;
  }

  h4 {
    opacity: 1;
  }
}

.flexCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
}

.justify-separator {
  margin-left: 15px;
}
