.decouvrir {
  .link-container {
    position: relative;
    width: 100%;
  }

  .nous-decouvrir,
  .nos-services,
  .nos-offres{
    position: absolute;
    top: -4rem;
    width: 100%;
  }

  .nous-decouvrir{
    color: $white;
  }

  .nos-services{
    color: $orange;
  }

  .nos-offres{
    color:$red-dyou;
  }

  .esper {
    margin: 0 auto;
  }

  .esper {
    width: 20%;
  }

  .link{
    font-weight: 600;
    font-size: 2.3rem;
  }

  .mini{
    text-transform: uppercase;
  }

  @media all and (min-width: 40em) and (max-width: 63.9375em) {
    .link{
      font-weight: 600;
      font-size: 1.3rem;
    }
  }

  @media all and (min-width: 64em) {
    .esper {
      width: 30%;
    }
  }
}
